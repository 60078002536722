/* You can add global styles to this file, and also import other style files */
$gutter: 0.5rem; //for primeflex grid system

@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeflex/primeflex.scss';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/prismjs/themes/prism-coy.css';

@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,400;0,700;1,400;1,700&family=Prompt:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import '../node_modules/ag-grid-community/dist/styles/ag-grid.css';
@import '../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css';

body {
  font-family: 'Chakra Petch', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Prompt', sans-serif;
}
h1 {
  @apply tw-py-2;
  font-size: 1.4rem;
}
h2 {
  @apply tw-py-2;
  font-size: 1.3rem;
}
h3 {
  @apply tw-py-2;
  font-size: 1.2rem;
}
h4 {
  @apply tw-py-2;
  font-size: 1.1rem;
}
h5,
h6 {
  font-size: 1rem;
}
.layout-topbar
  .layout-topbar-wrapper
  .layout-topbar-right
  .layout-topbar-actions
  > li
  > ul.search-item-submenu
  > li
  .search-input-wrapper
  input {
  width: 250px;
}

/* Fix theme */
body .ui-widget,
body .ui-widget .ui-widget {
  font-family: 'Chakra Petch', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.ui-panel-images {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

p-radiobutton {
  margin-right: 10px;
}

.p-display-grid-4 formly-field-primeng-radio {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.error {
  color: red !important;
}

.emptymessage {
  text-align: center !important;
}

// คลาสสำหรับทำ responsive div tag
.responsive-div {
  overflow-x: auto;
}

.responsive-div div {
  max-width: 100%;
}

// ปรับให้ข้อมุลในตาราง compact ขึ้น
.ag-theme-alpine .ag-cell {
  padding-left: 3px;
  padding-right: 3px;
}

// เพิ่ม padding ให้ divider เพื่อจะได้ห่างจากเส้น
.divider {
  padding-top: 20px;
}

// Hot fixes theme
// hot fixes
.card {
  border: 1px solid #827b91;
}

// hot fix ag grid
.ag-theme-alpine .ag-cell .p-dropdown label.p-dropdown-label {
  line-height: normal !important;
}

// status badge
.status-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  // text-transform: uppercase;
  font-weight: 700;
  // font-size: 12px;
  letter-spacing: 0.3px;
  width: 90px;
  text-align: center;
}

// status for any case
.status-active {
  background: #c8e6c9;
  color: #256029;
  width: 100px;
}

.status-inactive {
  background: #ffcdd2;
  color: #c63737;
  width: 100px;
}

// status for time
.status-normal {
  background: #c8e6c9;
  color: #256029;
}

.status-absent {
  background: #ffcdd2;
  color: #c63737;
}

.status-danger {
  background: #ffcdd2;
  color: #c63737;
}


.status-late {
  background: #feedaf;
  color: #8a5340;
}

.status-left-early {
  background: #b3e5fc;
  color: #23547b;
}

.status-late-and-left-early {
  background: #d77cdc;
  color: #2a0835;
}

.status-invalid {
  background: #eccfff;
  color: #694382;
}

.status-na {
  background: #ffd8b2;
  color: #805b36;
}

// status for request
.status-approved {
  background: #c8e6c9;
  color: #256029;
}

.status-waiting {
  background: #b3e5fc;
  color: #23547b;
}

.status-cancel {
  background: #ffcdd2;
  color: #c63737;
}

.status-editing {
  background: #feedaf;
  color: #8a5340;
}

.status-not-approve {
  background: #eccfff;
  color: #694382;
}

.status-warning {
  background: #efe6d3;
  color: #d89c19;
}
// fix dropdown text overflow
.p-dropdown-label-container {
  width: 100%;
}
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  text-overflow: ellipsis;
  padding-right: 2em !important;
}
// .p-dropdown .p-dropdown-label {
//   text-overflow: ellipsis;
//   padding-right: 1em !important;
// }
body .p-dropdown {
  min-width: 0;
}

@media print {
  .no-print,
  .no-print * {
    height: 0px !important;
    overflow: visible !important;
    display: none !important;
  }
  .layout-main {
    padding-top: 0px;
  }
}

.p-mb-d1 {
  margin-bottom: -0.5rem;
}
.p-mb-d2 {
  margin-bottom: -1rem;
}

.text-right {
  text-align: right;
}

.p-pb-0 {
  padding-bottom: 0 !important;
}

// mobile
@media (max-width: 560px) {
  .text-sm-center {
    text-align: center;
  }
}

// tablet
@media (max-width: 768px) {
  .text-md-center {
    text-align: center;
  }
}

// desktop
@media (min-width: 1024px) {
  .text-lg-start {
    text-align: left;
  }
}

// non-desktop
@media (max-width: 1023px) {
  .non-desktop-hide {
    display: none;
  }
}

.icon-action a {
  color: inherit;
}
.icon-action a:hover {
  color: inherit;
}

.icon-action i {
  color: inherit;
  cursor: pointer;
}
.mini-info-button {
  @apply tw-cursor-pointer tw-text-lg;
}

/* Fix ngb calendar */
ngb-datepicker {
  @apply tw-bg-white tw-text-center;
  z-index: 99999;
}
ngb-datepicker.dropdown-menu {
  @apply tw-p-2;
}

/* Fix swal z-index */
.swal2-container {
  z-index: 5000 !important;
}

/* Fix dialog footer */
.p-dialog-content .p-dialog-footer {
  @apply px-0;
}

.hidden, [hidden] {
  display: none !important;
}

